.App {
  text-align: center;
}

a {
  color: gray;
  text-decoration: none;
}

a:hover {
  color: #282c34;
  cursor: pointer;
}

#button_container {
  position: fixed;
  bottom: 5%;
  right: 5%;
  width: 20%;
  text-align: left;
  z-index: 999; /* Adjust the z-index as needed to ensure the button appears above other elements */
  opacity: 0.9;
}